// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$frontend-portal2-primary: mat.define-palette(mat.$indigo-palette);
$frontend-portal2-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$frontend-portal2-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$frontend-portal2-theme: mat.define-light-theme((color: (primary: $frontend-portal2-primary,
            accent: $frontend-portal2-accent,
            warn: $frontend-portal2-warn,
         )));


// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($frontend-portal2-theme);

/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';
@import "../node_modules/bootstrap/scss/bootstrap";

@import "../node_modules/shepherd.js/dist/css/shepherd.css";


// Font imports

@font-face {
   font-family: 'NotoSans-Light';
   src: url('/assets/fonts/Noto_Sans/NotoSans-Light.eot');
   /* IE9 Compat Modes */
   src: url('/assets/fonts/Noto_Sans/NotoSans-Light.eot?#iefix') format('embedded-opentype'),
      /* Modern Browsers */
      url('/assets/fonts/Noto_Sans/NotoSans-Light.ttf') format('truetype'),
      /* Safari, Android, iOS */
      url('/assets/fonts/Noto_Sans/NotoSans-Light.svg#svgFontName') format('svg');
   /* Legacy iOS */
}

@font-face {
   font-family: 'NotoSans-Regular';
   src: url('/assets/fonts/Noto_Sans/NotoSans-Regular.eot');
   /* IE9 Compat Modes */
   src: url('/assets/fonts/Noto_Sans/NotoSans-Regular.eot?#iefix') format('embedded-opentype'),
      /* Modern Browsers */
      url('/assets/fonts/Noto_Sans/NotoSans-Regular.ttf') format('truetype'),
      /* Safari, Android, iOS */
      url('/assets/fonts/Noto_Sans/NotoSans-Regular.svg#svgFontName') format('svg');
   /* Legacy iOS */
}

@font-face {
   font-family: 'NotoSans-SemiBold';
   src: url('/assets/fonts/Noto_Sans/NotoSans-SemiBold.eot');
   /* IE9 Compat Modes */
   src: url('/assets/fonts/Noto_Sans/NotoSans-SemiBold.eot?#iefix') format('embedded-opentype'),
      /* Modern Browsers */
      url('/assets/fonts/Noto_Sans/NotoSans-SemiBold.ttf') format('truetype'),
      /* Safari, Android, iOS */
      url('/assets/fonts/Noto_Sans/NotoSans-SemiBold.svg#svgFontName') format('svg');
   /* Legacy iOS */
}

/* DEFAULT STYLING */

* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}

body {
   font-family: Arial, Helvetica, sans-serif;
   position: relative;
}

input::placeholder {
   /* Chrome, Firefox, Opera, Safari 10.1+ */
   color: #869798;
   font-size: 14px;
   opacity: 1;
   /* Firefox */
}

input:-ms-input-placeholder {
   /* Internet Explorer 10-11 */
   font-size: 14px;
   color: #869798;
}

input::-ms-input-placeholder {
   /* Microsoft Edge */
   font-size: 14px;
   color: #869798;
}

input:focus {
   outline: none;
}

/* NG BOOTSTRAP MODAL DEFAULT STYLING */

.modal-content {
   border-radius: 25px !important;
}

.modal-header {
   background-color: #EFF5FB;
   padding: 2em !important;

   span {
      font-family: 'NotoSans-SemiBold';
      font-size: 18px;
      color: #566D76;
   }
}

.modal-body {
   max-height: 50vh;
   padding: 2em !important;
}

.modal-footer {
   padding: 1em 2em !important;

   button {
      width: 150px;
   }
}

/* CUSTOM CLASS STYLING */

.bulletPoint {
   display: list-item;
   list-style-type: disc;
}

.hr-header {
   display: flex;
   align-items: center;
   gap: 1em;

   hr {
      border-top: 1.5px solid #869798;
      opacity: 1;
      flex-grow: 1;
   }
}

.rows {
   display: flex;
   flex-direction: column;
   gap: 10px;

   .info-single-row {
      display: flex;
      gap: 10px;
   }

   .item {
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding: 0.7em;
      width: 100%;
   }
}

.actions {
   display: flex;
   justify-content: center;
   gap: 7px;

   img {
      cursor: pointer;
   }

   .icon {
      text-align: center;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      z-index: 2;
      transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);

      .btn {
         width: 25px;
         height: 25px;
         padding: 6px;
         cursor: pointer;
         background: #C8CFD0;
         border-radius: 50%;
         border: none;
         display: flex;
         align-items: center;
         justify-content: center;
      }
   }

   .icon .tooltip {
      position: absolute;
      top: 0;
      z-index: 3;
      background: #566D76;
      color: #fff;
      padding: 5px 9px;
      font-family: 'NotoSans-SemiBold';
      font-size: 10px;
      border-radius: 8px;
      opacity: 0;
      pointer-events: none;
      box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
   }

   .icon:hover .tooltip {
      top: -40px;
      opacity: 1;
      pointer-events: auto;
   }

   .icon .tooltip::before {
      position: absolute;
      content: "";
      height: 7px;
      width: 7px;
      background: #566D76;
      left: 50%;
      bottom: -3px;
      transform: translateX(-50%) rotate(45deg);
      transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
   }

   .info:hover .btn,
   .info:hover .btn:before {
      background: #3F84C8;
   }

   .report:hover .btn,
   .report:hover .btn:before {
      background: #8863B2;
   }

   .repeat:hover .btn,
   .repeat:hover .btn:before {
      background: #0090C6;
   }

   .block:hover .btn,
   .block:hover .btn:before {
      background: #B03B7A;
   }

   .delete:hover .btn,
   .delete:hover .btn:before {
      background: #B03B7A;
   }
}


.button-with-img {
   display: flex;
   align-items: center;
   border: none;
   cursor: pointer;
   background: transparent;
}

.flexRow5 {
   display: flex;
   gap: 5px;
}

.flexRow10 {
   display: flex;
   gap: 10px;
}

.flexColumn5 {
   display: flex;
   flex-direction: column;
   gap: 5px;
}

.flexColumn10 {
   display: flex;
   flex-direction: column;
   gap: 10px;
}

.pointer {
   cursor: pointer;
}

// mat auto complete z-index to work on ng modal
.cdk-overlay-container {
   z-index: 9000
}

.mat-button-toggle-standalone,
.mat-button-toggle-group {
   width: 100%;
   height: 40px;
   align-items: center;

   .mat-button-toggle {
      width: 100%;
      font-family: 'NotoSans-Regular';
      font-size: 14px;
   }
}

.matPaginator {
   margin-left: auto;
   margin-right: auto;
   padding-bottom: 100px;
   width: 93%;
   background-color: #F6F6F6 !important;
}

.rounded-white-background {
   background-color: white;
   border-radius: 25px;
}

.rounded-white-background15 {
   background-color: white;
   border-radius: 15px;
}

.rounded-green-background15 {
   background-color: #a2cea3;
   border-radius: 15px;
}

.rounded-image-50 {
   height: 50px;
   width: fit-content;
   border-radius: 25px;
}

.blue-fade-background {
   height: 100vh;
   width: 100vw;
   background: linear-gradient(to left, #2E92C5, #ADCEE4);
}

.input-icon-container {
   position: relative;
}

.input-icon-container img {
   position: absolute;
   left: 15px;
   top: 10px;
}


.form-input-title {
   font-size: 12px;
   color: #566d76;
   padding-left: 10px;
   padding-bottom: 5px;
   display: inline-block;
}

.form-input {
   background-color: #F6F6F6;
   height: 40px;
   border-radius: 25px;
   font-size: 14px;
   border-color: #F6F6F6;
   margin-bottom: 8px;
   width: 100%;
   border: hidden;
   padding-left: 40px;
   color: #566D76;
}

.form-input-10 {
   background-color: #F6F6F6;
   height: 40px;
   border-radius: 25px;
   font-size: 14px;
   border-color: #F6F6F6;
   margin-bottom: 8px;
   width: 100%;
   border: hidden;
   padding-left: 10px;
   color: #566D76;
}

.simple-form-input {
   margin: 0;
   padding-left: 10px;
}

select.form-input {
   border-right: 12px solid transparent;
}

.form-input-whitebg {
   background-color: white;
   min-height: 40px;
   border-radius: 25px;
   font-size: 16px;
   margin-bottom: 8px;
   width: 100%;
   border: hidden;
   padding-left: 20px;
   color: #373737;
}

.form-input-whitebg-5 {
   background-color: white;
   min-height: 40px;
   border-radius: 25px;
   font-size: 16px;
   margin-bottom: 8px;
   width: 100%;
   border: hidden;
   padding-left: 20px;
   color: #373737;
}

select.form-input-whitebg {
   border-right: 12px solid transparent;
}

.form-input::placeholder {
   color: #C8CFD0;
   padding-left: 0px;
}

.form-input:focus {
   outline: none;
}

input {
   color: #869798;
}

textarea.form-input-whitebg {
   resize: none;
   height: 100px;
   padding-right: 10px;
}

textarea.form-input-whitebg.bigger {
   height: 300px;
}

textarea.form-input-whitebg.conclusion {
   height: 100px;
}

textarea.form-input {
   resize: none;
   height: 150px;
   padding-right: 10px;
}

.box-container {
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   display: flex;
   justify-content: center;
   align-items: center;
}

.center-white-box {
   width: 50%;
   height: auto;
   background-color: white;
   border-radius: 50px;
   padding: 1em;

   .below-form {
      margin-top: 1em;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 1em;
   }
}

.primary-button {
   border-radius: 25px;
   background: #0090c6ff;
   color: white;
   width: 100%;
   height: 44px;
   border: hidden;
   font-size: 14px;
   font-family: 'NotoSans-SemiBold';
}

.secondary-button {
   border-radius: 25px;
   border: 2px solid #0090c6ff;
   color: #0090c6ff;
   background-color: white;
   width: 100%;
   height: 44px;
   font-size: 14px;
   font-family: 'NotoSans-SemiBold';
}

.invalid-label {
   color: #B03B7A;
   display: block;
   margin-bottom: 1em;
   font-size: 12px;
}

.main-content-sidebar {
   height: 100%;
   width: 100%;
   overflow-y: auto;
   background-color: #F6F6F6;
   box-shadow: -10px 0 20px -20px #333;

   .main-content {
      margin: 2em;

      .header {
         display: flex;
         justify-content: space-between;
         align-items: center;
         margin-bottom: 2em;

         .title {
            font-family: 'NotoSans-SemiBold';
            font-size: 18px;
            color: #29363D;
         }

         .close-button {
            cursor: pointer;
            height: 16px;
            width: 16px;
         }
      }
   }
}

.title-container {
   display: flex;
   gap: 1em;
   cursor: pointer;
}

.header-container {
   display: flex;
   align-items: center;
   padding-bottom: 1em;
   justify-content: space-between;
   flex-wrap: wrap;
   gap: 1.5em;
}

.mat-option-text {
   color: #869798 !important;
   font-size: 14px;
   font-family: 'NotoSans-Regular';
}

// header breadcrumb filter order tooltip custom css
.white-bg-tooltip .tooltip-inner {
   background-color: white;
}

.white-bg-tooltip.bs-tooltip-end .tooltip-arrow::before {
   border-right-color: white;
}

.white-bg-tooltip.bs-tooltip-start .tooltip-arrow::before {
   border-left-color: white;
}

.white-bg-tooltip.bs-tooltip-top .tooltip-arrow::before {
   border-top-color: white;
}

.white-bg-tooltip.bs-tooltip-bottom .tooltip-arrow::before {
   border-bottom-color: white;
}

// header breadcrumb filter order tooltip custom css
.white-bg-tooltip-dark-text .tooltip-inner {
   background-color: white;
   color: #29363D;
}

.white-bg-tooltip-dark-text.bs-tooltip-end .tooltip-arrow::before {
   border-right-color: white;
}

.white-bg-tooltip-dark-text.bs-tooltip-start .tooltip-arrow::before {
   border-left-color: white;
}

.white-bg-tooltip-dark-text.bs-tooltip-top .tooltip-arrow::before {
   border-top-color: white;
}

.white-bg-tooltip-dark-text.bs-tooltip-bottom .tooltip-arrow::before {
   border-bottom-color: white;
}

// header breadcrumb filter order tooltip custom css
.blue-bg-tooltip .tooltip-inner {
   background-color: #0090C6;
}

.blue-bg-tooltip.bs-tooltip-end .tooltip-arrow::before {
   border-right-color: #0090C6;
}

.blue-bg-tooltip.bs-tooltip-start .tooltip-arrow::before {
   border-left-color: #0090C6;
}

.blue-bg-tooltip.bs-tooltip-top .tooltip-arrow::before {
   border-top-color: #0090C6;
}

.blue-bg-tooltip.bs-tooltip-bottom .tooltip-arrow::before {
   border-bottom-color: #0090C6;
}

/* custom scroll bar color */

::-webkit-scrollbar {
   width: 10px;
   height: 10px;
}

::-webkit-scrollbar-track {
   box-shadow: inset 0 0 10px 10px transparent;
   border: solid 3px transparent;
}

::-webkit-scrollbar-thumb {
   box-shadow: inset 0 0 10px 10px #0090C6;
   border: solid 3px transparent;
   border-radius: 10px;
}

/* DEFAULT TABLE STYLING */

.basic-table {
   display: flex;
   flex-direction: column;
   gap: 0.5em;
   // overflow: auto;
   min-width: fit-content;
}

.table-header-container {
   position: relative;
   // min-width: max-content;
   display: flex;
   flex-direction: column;
}

.table-header {
   background-color: #bfc3c6;
   border-radius: 25px;
   color: white;
   font-family: 'NotoSans-SemiBold';
   font-size: 14px;
   display: flex;
   align-items: center;
   margin-bottom: 0.5em;
   min-width: 700px;
   height: 40px;

   span {
      text-align: center;
      padding: 0 10px;
      display: inline-block;
      min-width: 100px;
   }

}

.table-data-row {
   background-color: white;
   border-radius: 25px;
   color: #45484a;
   border: 0.5px solid #E5E5E5;
   font-family: 'NotoSans-Regular';
   font-size: 12px;
   display: flex;
   align-items: center;
   min-width: 700px;
   padding-right: 5px;
   margin-bottom: 10px;
   min-height: 40px;
   cursor: pointer;

   span {
      text-align: center;
      padding: 0 10px;
      display: inline-block;
      min-width: 100px;
      word-wrap: break-word;
   }

}

/* CUSTOM FONT SIZES/STYLES */

// FONT LIGHT

.fontLight10 {
   font-family: 'NotoSans-Light';
   font-size: 12px;
}

.fontLight12 {
   font-family: 'NotoSans-Light';
   font-size: 14px;
}

.fontLight15 {
   font-family: 'NotoSans-Light';
   font-size: 16px;
}

// FONT REGULAR

.fontRegular8 {
   font-family: 'NotoSans-Regular';
   font-size: 10px;
}

.fontRegular9 {
   font-family: 'NotoSans-Regular';
   font-size: 11px;
}

.fontRegular10 {
   font-family: 'NotoSans-Regular';
   font-size: 12px;
}

.fontRegular11 {
   font-family: 'NotoSans-Regular';
   font-size: 13px;
}

.fontRegular12 {
   font-family: 'NotoSans-Regular';
   font-size: 14px;
}

.fontRegular13 {
   font-family: 'NotoSans-Regular';
   font-size: 15px;
}

.fontRegular16 {
   font-family: 'NotoSans-Regular';
   font-size: 18px;
}

.fontRegular22 {
   font-family: 'NotoSans-Regular';
   font-size: 24px;
}

// FONT SEMI BOLD

.fontSemiBold8 {
   font-family: 'NotoSans-SemiBold';
   font-size: 10px;
}

.fontSemiBold10 {
   font-family: 'NotoSans-SemiBold';
   font-size: 12px;
}

.fontSemiBold12 {
   font-family: 'NotoSans-SemiBold';
   font-size: 14px;
}

.fontSemiBold11 {
   font-family: 'NotoSans-SemiBold';
   font-size: 13px;
}

.fontSemiBold13 {
   font-family: 'NotoSans-SemiBold';
   font-size: 15px;
}

.fontSemiBold14 {
   font-family: 'NotoSans-SemiBold';
   font-size: 16px;
}

.fontSemiBold16 {
   font-family: 'NotoSans-SemiBold';
   font-size: 18px;
}

.fontSemiBold18 {
   font-family: 'NotoSans-SemiBold';
   font-size: 20px;
}

.fontSemiBold20 {
   font-family: 'NotoSans-SemiBold';
   font-size: 22px;
}

.fontSemiBold22 {
   font-family: 'NotoSans-SemiBold';
   font-size: 24px;
}

.fontSemiBold26 {
   font-family: 'NotoSans-SemiBold';
   font-size: 28px;
}


// FONT COLORS

.fontColorPrimaryTeleheart {
   color: #4991A6;
}

.fontColorWhite {
   color: #ffffff;
}

.fontColorPrimary {
   color: #0090C6;
}

.fontColorWarning {
   color: #B03B7A;
}

.fontColorPurple {
   color: #6775C2;
}

.fontColorYellowWarning {
   color: #f89e16;
}

.fontColorDefaultGray {
   color: #29363D;
}

.fontColorDarkGray {
   color: #566d76;
}

.fontColorMediumDarkGray {
   color: #869798;
}

.fontColorMediumGray {
   color: #C8CFD0;
}

.fontColorMediumLightGray {
   color: #E5E5E5;
}

.fontColorLightGray {
   color: #F6F6F6;
}

.fontColorSuccess {
   color: #336934;
}

/* MEDIA QUERIES */

@media (max-width: 1200px) {}

@media (max-width: 992px) {
   .center-white-box {
      width: 60%;
   }

   .main-content-sidebar {
      .main-content {
         margin: 1em;
      }

   }

   .header-container {
      padding-bottom: 5px;
   }
}

@media (max-width: 768px) {
   textarea.form-input-whitebg.conclusion {
      height: 300px;
   }

   .center-white-box {
      width: 70%;

      .logo {
         width: 70%;
      }

      .below-form {
         flex-direction: column;
      }
   }

   textarea.form-input-whitebg {
      font-size: 21px;
   }
}

@media (max-width: 576px) {
   .center-white-box {
      width: 80%;

      .logo {
         width: 100%;
      }
   }

   /* NG BOOTSTRAP MODAL DEFAULT VALUES */
   .modal-body {
      padding: 1em !important;
   }

   .modal-header {
      background-color: #EFF5FB;
      padding: 1em !important;
   }

   .modal-footer {
      padding: 1em !important;
   }

}

@media (max-width: 310px) {
   .center-white-box {
      width: 90%;

      .logo {
         width: 100%;
      }
   }
}

html,
body {
   height: 100%;
}

body {
   margin: 0;
   font-family: Roboto, "Helvetica Neue", sans-serif;
}